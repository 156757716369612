//
// Separate an array into two arrays based on a predicate
// partition([1, 2, 3, 4], (n) => n % 2 === 0) // [[2, 4], [1, 3]]
//
export function partition<T>(
  arr: T[],
  predicate: (item: T) => boolean,
): [T[], T[]] {
  const passed: T[] = [];
  const failed: T[] = [];
  arr.forEach((item) => {
    predicate(item) ? passed.push(item) : failed.push(item);
  });
  return [passed, failed];
}

//
// Get the user agent id from the cookie or create a new one
//
export function ensureUserAgentId() {
  const uaid = document.cookie.match(/uaid=([^;]+)/)?.[1];
  if (uaid) return;

  const newUaid = "ua-" + Math.random().toString(36).substring(2, 15);
  // We omit the expiration date here and allow the server to set it
  document.cookie = `uaid=${newUaid}; path=/; SameSite=Strict`;
}
