import { ensureUserAgentId } from "../scripts/util";

const supportLink = document.getElementById("support-link")! as HTMLAnchorElement;

const SUPPORT_EMAIL = process.env["SUPPORT_EMAIL"];

// Add the support link
if (SUPPORT_EMAIL) {
  supportLink.href = `mailto:${SUPPORT_EMAIL}`;
  supportLink.hidden = false;
  supportLink.innerText = SUPPORT_EMAIL;
}

const overlay = document.querySelector('#overlay')! as HTMLElement;

// Show the element with the data-help-id matching the data-help-target
// If we click anywhere else, hide the element
document.querySelector('a[data-help-target]')?.addEventListener('click', (e) => {
  const target = e.currentTarget as HTMLElement;
  const helpId = target.dataset.helpTarget;
  const helpElement = document.querySelector(`[data-help-id="${helpId}"]`) as HTMLElement;

  if (helpElement) {
    helpElement.hidden = false;
    overlay.style.visibility = 'visible';
    overlay.addEventListener('click', () => {
      helpElement.hidden = true;
      overlay.style.visibility = 'hidden';
    }, { once: true });
  }
});

// Set a cookie for anonymous users
ensureUserAgentId();
